/*系统设置&系统日志&关系系统的配置*/

import {req} from "../utils/request";

export class System {

  /**
   * 获取 导航数据
   * @param params
   * @returns {Promise<*|void>}
   */
  getNavList = async (params) => {
    return req.post('user.system/getNavList', params);
  }

  /**
   * 获取 导航数据
   * @param params
   * @returns {Promise<*|void>}
   */
  getNavMainList = async (params) => {
    return req.post('user.system/getNavMainList', params);
  }

  /**
   * 获取 导航数据
   * @param params
   * @returns {Promise<*|void>}
   */
  getNavSecondList = async (params) => {
    return req.post('user.system/getNavSecondList', params);
  }

  /**
   * 获取 导航数据
   * @param params
   * @returns {Promise<*|void>}
   */
  getNavFooterList = async (params) => {
    return req.post('user.system/getNavFooterList', params);
  }

  /**
   * 获取 广告，轮播图，banner
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getAdList = async(params) => {
    return req.post('user.ad/getList', params);
  }

  /**
   * 获取日志数据
   * @param params
   * @returns {Promise<*|void>}
   */
  getLogList = async (params) => {
    return req.post('user.system/getLogList', params);
  }

  /**
   * 删除日志
   * @param params
   * @returns {Promise<*|void>}
   */
  delLog = async (params) => {
    return req.post('user.system/delLog', params);
  }

  /**
   * 登录 角色的菜单
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getMenuTree = async (params) => {
    return req.post('user.System/getMenuTree', params);
  }

  /**
   *  清除缓存
   * @param params
   * @returns {Promise<*|void>}
   */
  clearCache = async (params) => {
    return req.post('user.System/clearCache', params);
  }

  /**
   *  获取配置
   * @param params
   * @returns {Promise<*|void>}
   */
  getConfig = async (params) => {
    return req.post('user.System/getConfig', params);
  }

}

export default new System();