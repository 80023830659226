<template>
  <!--内容-->
  <div class="page-schemeDetail" style="height: 100Vh">
    <div class="gk-container gk-clear">
      <div class="detail-content">
        <h1>{{ detail.title }}</h1>
        <div class="desc">
          <div class="tag">{{ detail.description }}</div>
          <div class="static">
            <span><img src="/static/iconImg/icon-view02.png" alt="">{{ detail.view }}</span>
            <span><img src="/static/iconImg/icon-support.png" alt="">{{ detail.very_good }}</span>
            <span><img src="/static/iconImg/icon-comment.png" alt="">{{ detail.comment }}</span>
          </div>
        </div>
        <div class="detail">
          <div v-html="detail.content"></div>
          <pdf_viewer :pdfUrl="detail.file"></pdf_viewer>
        </div>
        <!--   点赞-->
        <div class="btn-like" @click="veryGood"><img src="/static/iconImg/icon-support2.png" alt="点赞"></div>
        <!--  举报等操作   -->
        <div class="detail-op-btns">
          <div class="left this-mouse-pointer" @click="touSu"><img src="/static/iconImg/icon-danger.png" alt="">举报</div>
          <div class="right">
            <span @click="favoriteArticle" class="this-mouse-pointer"><img src="/static/iconImg/icon-favarite.png"
                                                                           alt="">{{ favorite_txt }}</span>
            <span @click="share" class="this-mouse-pointer"><img src="/static/iconImg/icon-share.png" alt="">分享</span>
          </div>
        </div>
        <!--  评论区域-->
        <!--        <div class="comment-mod">
                  <div class="tit">评论</div>
                  <div class="comment-cont">
                    <img class="advater" src="/static/upload/advater.png" alt="">
                    <div class="det">
                      <input type="text" placeholder="说两句吧~">
                      <span>发布</span>
                    </div>
                  </div>
                </div>-->
      </div>
      <div class="side-bar">
        <!-- 作者信息-->
        <div class="user-info">
          <el-link href="javascript:;">
            <div class="info">
              <img :src="account.avatar" :alt="account.nickname">
              <div class="text">
                <div class="name">{{ account.nickname }}</div>
                <span>{{ account.residecity }} ｜ {{ account.profession }}</span>
              </div>
            </div>
          </el-link>
          <div class="desc">
            <span v-for="(m,index) in authorStatistics" :key="index">{{ m.name }}<i>{{ m.num }}</i></span>
          </div>
          <div class="btns">
            <span class="btn01 this-mouse-pointer" @click="attentionUser">{{ attention_txt }}</span>
            <!--            <span class="btn02 this-mouse-pointer">定制方案</span>-->
          </div>
        </div>
        <!--   会员价格-->
        <!--        <div class="price-mod">
                  <div class="price">
                    <span class="txt01">价格：</span>
                    <span class="price01">¥19.9</span>
                    <div class="vip">
                      <span>作品定价</span>
                      <span>¥9.9</span>
                    </div>
                  </div>
                  <div class="vip-scheme">
                    <div class="tit">开通方案SVIP，此方案免费</div>
                    <div class="desc">10w+方案免费下，日更300+</div>
                    <div class="btn">立即开通SVIP</div>
                  </div>
                  <div class="vip-btn">
                    <span>付费看完</span>
                    <span>付费下载</span>
                  </div>
                </div>-->
        <!--   喜欢该作品的还看了 列表-->
        <div class="relate-list">
          <div class="tit">喜欢该作品的还看了</div>
          <ul>
            <li v-for="(m,index) in like_data" v-bind:key="index">
              <div class="pic">
                <router-link :to="'/detail/'+m.id">
                  <img :src="m.img" :alt="m.title">
                </router-link>
              </div>

              <div class="txt">
                <h3>
                  <router-link :to="'/detail/'+m.id">{{ m.title }}</router-link>
                </h3>
                <div class="price"><span>作品定价</span>¥{{ m.price }}</div>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import user from "@/api/user";
import pdf_viewer from "@/components/pdf_viewer";
import {mapState} from "vuex";

export default {
  name: "detail",
  components: {pdf_viewer},
  computed: {
    ...mapState(['account'])
  },
  props: {
    detail: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      id: 0,
      attention_txt: "  + 关注",
      favorite_txt: "收藏",
      like_data: [],
      authorStatistics: [],
      loading: false,
    }
  },
  created() {
    this.getAuthorStatistics();
    this.getArticleMy();
  },
  methods: {
    //调取 用户 作品，关注，收藏 统计
    getAuthorStatistics() {
      this.loading = true
      user.getAuthorStatistics({
        uid: 'no'
      }).then(res => {
        if (res) {
          this.authorStatistics = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //投诉
    touSu() {
      this.$message.warning("预览页面投诉功能禁用！");
    },
    //分享
    share() {
      this.$message.warning("预览页面分享功能禁用！");
    },
    //点赞
    veryGood() {
      this.$message.warning("预览页面点赞功能禁用！");
    },
    //关注作者
    attentionUser() {
      this.$message.warning("预览页面关注功能禁用！");
    },
    //收藏作品
    favoriteArticle() {
      this.$message.warning("预览页面收藏功能禁用！");
    },
    //推荐看
    getArticleMy() {
      user.getArticleMy({
        cid: this.detail.cid
      }).then(res => {
        if (res) {
          this.like_data = res.data.data;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.this-mouse-pointer {
  cursor: pointer;
}
</style>